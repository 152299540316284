body {
  font-size: 1rem;
}

@font-face {
  font-family: "MuseoSans";
  src: url(/src/assets/fonts/MuseoSans.bin);
}

@font-face {
  font-family: "MuseoSans700";
  src: url(/src/assets/fonts/MuseoSans700.bin);
}

@font-face {
  font-family: "MuseoSans500";
  src: url(/src/assets/fonts/MuseoSans500.bin);
}

@font-face {
  font-family: "MuseoSans300";
  src: url(/src/assets/fonts/MuseoSans300.bin);
}

@font-face {
  font-family: "MuseoSlab";
  src: url(/src/assets/fonts/MuseoSlab.bin);
}

@font-face {
  font-family: "MuseoSlab700";
  src: url(/src/assets/fonts/MuseoSlab700.bin);
}

@font-face {
  font-family: "MuseoSlab500";
  src: url(/src/assets/fonts/MuseoSlab500.bin);
}

@font-face {
  font-family: "MuseoSlab300";
  src: url(/src/assets/fonts/MuseoSlab300.bin);
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~antd/dist/antd.css";

.plum-oval {
  content: "";
  position: absolute;
  background-color: #5d1049;
  left: -70px;
  right: -70px;
  bottom: 0;
  top: 0px;
  height: 210px;
  z-index: -1;
  border-bottom-left-radius: 40%;
  border-bottom-right-radius: 40%;
}

.ant-radio-inner::after {
  background: #5d1049;
}

.ant-radio-checked::after {
  border: 1px solid #5d1049;
}
.ant-switch-checked {
  background-color: #5d1049 !important;
}

.ant-tabs-tab-btn {
  opacity: 0.7;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #5d1049 !important;
}

.ant-tabs-tab:hover {
  color: #5d1049 !important;
}

.ant-tabs-ink-bar {
  background-color: #5d1049 !important;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 3px;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  opacity: 1;
}

.ant-form {
  font-size: 1rem;
  line-height: 1.3rem;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #5d1049;
  border-color: #5d1049;
}

.ant-picker-suffix {
  color: #5d1049;
}

.ant-picker {
  width: 100%;
  padding: 12px;
}

.ant-picker-input:focus {
  outline: none;
}

.ant-menu-sub.ant-menu-inline {
  background: #efe9ee;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  height: 24px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: none;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active {
  color: black;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}

.ant-menu-light .ant-menu-submenu-title:hover {
  color: black;
}

.grecaptcha-badge {
  visibility: hidden;
}

.ant-menu-inline {
  border: none;
}

.ant-popover-inner-content {
  padding: 0;
}


.ant-layout-header{
  height: auto;
}

/* .ant-layout-header {
  position:fixed;
  width:100%;
  left:0;
  top:0;
  right: 0;
  z-index: 1000;
} */
.ant-modal-mask {
  background-color: rgba(113, 4, 93, 0.30);
}

.ant-drawer-header {
  @apply bg-plumLightest;
}

.ant-drawer-title {
  @apply text-plum;
}

.ant-drawer-close {
  @apply text-plum;
}

.ant-modal-header {
  @apply bg-plumLightest;
}

.ant-modal-close {
  @apply text-plum;
}

.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav {
  @apply bg-plumLightest;
  @apply px-4;
  @apply pb-1;
}

.ant-table-thead > tr > th {
  @apply bg-white;
  @apply font-bold;
  @apply text-plum;
}

.ant-pagination-item-active, .ant-pagination-item:hover {
  @apply border-plum;
}
.ant-pagination-item-active a, .ant-pagination-item a {
  @apply text-plum;
}

.ant-pagination-item-link .anticon {
  @apply flex;
  @apply items-center;
  @apply justify-center;
}

.ant-btn-primary {
  @apply border-plum;
  @apply bg-plum;
}

a:hover {
  @apply text-plum;
}